import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { Practice, zone } from "@remhealth/apollo";
import { Button, Callout, Checkbox, FormGroup, HTMLTable, Icon, Intent, Popover, Spinner, Switch, useAbort, useStateMap, useStateSet } from "@remhealth/ui";
import { CloudDownload, EyeOpen, Issue, SmallTick, WarningSign } from "@remhealth/icons";
import { getProductFlag, practiceClients, registryClients, useAccessToken, useApollo } from "@remhealth/host";
import { Form, FormActions, FormContent, PracticeSelect } from "@remhealth/core";
import { MesaContext } from "~/contexts";
import type { MesaConfig } from "~/config";
import { importPracticePreferences } from "./imports/practicePreferences";
import { importSnippets } from "./imports/snippets";
import { importTextExpansions } from "./imports/textExpansions";
import { importNoteRules } from "./imports/noteRules";
import { importSpeechRules } from "./imports/speechRules";
import { importNoteSectionForms } from "./imports/noteSectionForms";
import { importNoteDefinitions } from "./imports/noteDefinitions";
import { importNoteTemplates } from "./imports/noteTemplates";
import { importGroupNoteDefinitions } from "./imports/groupNoteDefinitions";
import { updateGroups } from "./imports/groups";
import { importItineraries } from "./imports/itineraries";
import type { ImportItemSkipReason, ImportProps } from "./imports/common";
import { createReferenceMapperFactory } from "./imports/referenceMapper";
import { CollapsibleRow, Container, IntentCell, PopoverContent } from "./dataImport.styles";

const importTypes = [
  "Snippet",
  "TextExpansion",
  "NoteRule",
  "NoteSectionForm",
  "NoteDefinition",
  "NoteTemplate",
  "GroupNoteDefinition",
  "Group",
  "Itinerary",
  "SpeechRule",
  "PracticePreference",
] as const;

type ImportType = typeof importTypes[number];

interface DataImportFormValues {
  requestedTypes: Record<ImportType, boolean>;
}

const initialValues = getInitialFormValues();

export interface DataImportProps {
  practice: Practice;
}

export function DataImport(props: DataImportProps) {
  const { practice } = props;

  const abort = useAbort();
  const apollo = useApollo();

  const mesa = useContext(MesaContext);
  const accessToken = useAccessToken();

  const importResults = useStateMap(new Map<ImportType, ImportResult>());

  const [importing, setImporting] = useState(false);
  const [trainPractice, setTrainPractice] = useState<Practice | null>(null);
  const [trainConfig, setTrainConfig] = useState<MesaConfig>();
  const [prodToTrain, setProdToTrain] = useState(false);
  const [preview, setPreview] = useState(false);

  const trainClient = useMemo(() => {
    return trainConfig && trainPractice ? practiceClients(trainConfig.urls.apollo, accessToken, { networkId: trainPractice.networkId }) : null;
  }, [trainConfig, accessToken, trainPractice]);

  const sourceClient = useMemo(() => prodToTrain ? apollo : trainClient, [prodToTrain, apollo, accessToken, trainClient]);
  const targetClient = useMemo(() => prodToTrain ? trainClient : apollo, [prodToTrain, apollo, accessToken, trainClient]);

  const expandedRows = useStateSet<ImportType>([]);

  let typeList = [...importTypes];
  const allowGroupTopicManagement = getProductFlag(practice, "AllowGroupTopicManagement");
  typeList = typeList.filter(t => allowGroupTopicManagement || t !== "Itinerary");

  useEffect(() => {
    loadTrainConfig();
  }, [accessToken]);

  if (!trainConfig) {
    return <>Loading...</>;
  }

  return (
    <Form<DataImportFormValues>
      initialValues={initialValues}
      onSubmit={handleImport}
    >
      {form => (
        <Container>
          <Callout intent="primary">
            <p>The data import will only add items not currently present in {prodToTrain ? zone.prod : zone.train}.</p>
            <p>Any items that were previously imported will <strong style={{ textDecoration: "underline" }}>not</strong> be updated or deleted.</p>
          </Callout>
          <FormGroup label="Select a practice from Train">
            <PracticeSelect
              apolloUrl={trainConfig.urls.apollo}
              selectedPractice={trainPractice}
              token={accessToken}
              onSelect={setTrainPractice}
            />
          </FormGroup>
          <Switch
            inline
            alignIndicator="right"
            checked={prodToTrain}
            innerLabel={`Copy from ${zone.train} to ${zone.prod}`}
            innerLabelChecked={`Copy from ${zone.prod} to ${zone.train}`}
            label="Reverse Direction?"
            onChange={handleDirectionChange}
          />
          <HTMLTable compact>
            <thead>
              <tr>
                <th />
                <th />
                <th className="nameCell">
                  <Checkbox
                    checked={[...Object.values(form.values.requestedTypes)].every(c => !!c)}
                    label="Type"
                    onChange={(e) => handleCheckAllChange(e, form)}
                  />
                </th>
                <th />
              </tr>
            </thead>
            {typeList.map(type => renderResult(form, type))}
          </HTMLTable>
          <Switch
            inline
            large
            checked={preview}
            label="Preview Only"
            onChange={handlePreviewChange}
          />
          <Button
            large
            disabled={importing || !sourceClient || !targetClient}
            icon={preview ? <EyeOpen /> : <CloudDownload />}
            intent="primary"
            label={`${preview ? "Preview import" : "Import"} from ${prodToTrain ? zone.prod : zone.train} to ${prodToTrain ? zone.train : zone.prod}`}
            outlined={preview}
            onClick={() => form.submitForm()}
          />
        </Container>
      )}
    </Form>
  );

  function handleDirectionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setProdToTrain(event.currentTarget.checked);
  }

  function handlePreviewChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPreview(event.currentTarget.checked);
  }

  function renderResult(form: FormContent<DataImportFormValues>, importType: ImportType) {
    const importResult = importResults.get(importType);
    let finishItems = 0;
    const items: ImportItem[] = [];
    importResult?.items.forEach((item) => {
      if (item.success || item.failed || item.skipReason) {
        finishItems++;
      }
      items.push(item);
    });

    const finished = importResult?.done || importResult?.total === 0;
    const isOpen = expandedRows.has(importType);
    return (
      <tbody key={importType} className="with-header">
        <tr onClick={() => handleRowClicked(importType)}>
          <td className="caretCell">
            {importResult && <Icon icon={isOpen ? "caret-down" : "caret-right"} size={14} />}
          </td>
          <td className="iconCell">
            {importResult && <Spinner intent={finished ? Intent.SUCCESS : Intent.PRIMARY} size={16} value={finished ? 1 : finishItems / importResult.total} />}
          </td>
          <td className="nameCell">
            <Checkbox checked={form.values.requestedTypes[importType]} disabled={importing} label={getCheckboxLabel(importType)} onClick={() => handleCheckboxToggle(form, importType)} />
          </td>
          <td>{importResult && <>{finishItems} of {importResult?.total ?? 0}</>}</td>
        </tr>
        {items.map((item, idx) => {
          const msg = getGeneralMessage(item);
          return (
            <Fragment key={idx}>
              <CollapsibleRow $isOpen={isOpen}>
                <td />
                <td className="iconCell">{renderIcon(item)}</td>
                <td className="nameCell">
                  <Popover key={item.id} minimal content={renderPopover(item)}>
                    {item.name}
                  </Popover>
                </td>
                <IntentCell $intent={msg[1]}>
                  {msg[0]}
                </IntentCell>
              </CollapsibleRow>
              {item.errors.length > 1 && item.errors.map((error, eidx) => (
                <CollapsibleRow key={eidx} $isOpen={isOpen}>
                  <td />
                  <td />
                  <td />
                  <td>{error}</td>
                </CollapsibleRow>
              ))}
            </Fragment>
          );
        })}
      </tbody>
    );
  }

  function renderPopover(item: ImportItem): JSX.Element {
    return (
      <PopoverContent>
        <div>{item.id}</div>
        {item.deletedId && <div>Deleted: {item.deletedId}</div>}
      </PopoverContent>
    );
  }

  function renderIcon(item: ImportItem): JSX.Element {
    if (item.skipReason) {
      return <Icon icon={<SmallTick />} intent={Intent.NONE} />;
    }
    if (item.failed) {
      return <Icon icon={<WarningSign />} intent={Intent.DANGER} />;
    }
    if (item.errors.length) {
      return <Icon icon={<Issue />} intent={Intent.WARNING} />;
    }
    if (item.success) {
      return <Icon icon={<SmallTick />} intent={Intent.SUCCESS} />;
    }
    return <Spinner size={16} />;
  }

  async function loadTrainConfig() {
    const trainConfig = await mesa.configLoader("train");
    setTrainConfig(trainConfig);

    // Try to auto-select practice if an obvious choice is found
    const registry = registryClients(trainConfig.urls.apollo, accessToken);
    const trainPractices = await registry.practices.query({
      filters: [{ networkId: { equalTo: practice.networkId } }],
    });

    if (trainPractices.results.length === 1) {
      setTrainPractice(trainPractices.results[0]);
    }
  }

  function setAllChecked(form: FormActions<DataImportFormValues>, checked: boolean) {
    form.setValues(values => {
      Object.keys(values.requestedTypes).forEach(key => {
        values.requestedTypes[key as ImportType] = checked;
      });
      return values;
    });
  }

  function handleCheckAllChange(event: React.ChangeEvent<HTMLInputElement>, form: FormContent<DataImportFormValues>) {
    setAllChecked(form, event.currentTarget.checked);
    form.setValues(form.values);
  }

  function handleCheckboxToggle(form: FormContent<DataImportFormValues>, importType: ImportType) {
    form.values.requestedTypes[importType] = !form.values.requestedTypes[importType];
    form.setValues(form.values);
  }

  function handleRowClicked(importType: ImportType) {
    expandRows(importType, !expandedRows.has(importType));
  }

  function expandRows(importType: ImportType, expand: boolean) {
    if (expand) {
      expandedRows.add(importType);
    } else {
      expandedRows.delete(importType);
    }
  }

  async function handleImport(values: DataImportFormValues, formActions: FormActions<DataImportFormValues>) {
    if (!sourceClient || !targetClient || !trainPractice) {
      return;
    }

    setImporting(true);

    for (const type of typeList) {
      if (!values.requestedTypes[type]) {
        continue;
      }

      createResult(type);
      expandRows(type, true);

      const sourcePractice = prodToTrain ? practice : trainPractice;
      const targetPractice = prodToTrain ? trainPractice : practice;

      const defaultProp: ImportProps = {
        sourcePractice,
        targetPractice,
        preview,
        sourceClient,
        targetClient,
        referenceFactory: createReferenceMapperFactory(sourceClient, targetClient, targetPractice),
        abort: abort.signal,
        onUpdate: (count: number) => updateResult(type, count),
        onItemCreate: (id: string, name: string) => addItem(type, id, name),
        onItemUpdate: (id: string, deletedId: string) => updateItem(type, id, deletedId),
        onItemSkip: (id: string, reason: ImportItemSkipReason) => addItemSkip(type, id, reason),
        onItemError: (id: string, message: string, failed?: boolean) => addItemError(type, id, message, failed),
        onItemComplete: (id: string) => markItemComplete(type, id),
      };

      switch (type) {
        case "Snippet":
          await importSnippets(defaultProp);
          break;

        case "TextExpansion":
          await importTextExpansions(defaultProp);
          break;

        case "NoteRule":
          await importNoteRules(defaultProp);
          break;

        case "NoteSectionForm":
          await importNoteSectionForms(defaultProp);
          break;

        case "NoteDefinition":
          await importNoteDefinitions(defaultProp);
          break;

        case "NoteTemplate":
          await importNoteTemplates(defaultProp);
          break;

        case "GroupNoteDefinition":
          await importGroupNoteDefinitions(defaultProp);
          break;

        case "Group":
          await updateGroups(defaultProp);
          break;

        case "Itinerary":
          await importItineraries(defaultProp);
          break;

        case "SpeechRule":
          await importSpeechRules(defaultProp);
          break;

        case "PracticePreference":
          await importPracticePreferences(defaultProp);
          break;
      }

      markComplete(type);
      values.requestedTypes[type] = false;
      formActions.setFieldValue("requestedTypes", values.requestedTypes);
    }

    setAllChecked(formActions, false);
    setImporting(false);
  }

  function createResult(importType: ImportType) {
    if (importResults.has(importType)) {
      importResults.delete(importType);
    }
    importResults.set(importType, { items: new Map(), total: 0, done: false });
  }

  function updateResult(importType: ImportType, count: number) {
    const result = importResults.get(importType);
    if (result) {
      result.total += count;
      importResults.set(importType, result);
    }
  }

  function markComplete(importType: ImportType) {
    const result = importResults.get(importType);
    if (result) {
      result.done = true;
      importResults.set(importType, result);
    }
  }

  function addItem(importType: ImportType, id: string, name: string) {
    const result = importResults.get(importType);
    if (result) {
      result.items.set(id, { id, name, errors: [], failed: false, success: false });
      importResults.set(importType, result);
    }
  }

  function addItemSkip(importType: ImportType, id: string, reason: ImportItemSkipReason) {
    const result = importResults.get(importType);
    if (result) {
      const item = result.items.get(id);
      if (item) {
        item.skipReason = reason;
        importResults.set(importType, result);
      }
    }
  }

  function addItemError(importType: ImportType, id: string, message: string, failed?: boolean) {
    const result = importResults.get(importType);
    if (result) {
      const item = result.items.get(id);
      if (item) {
        item.errors.push(message);
        if (failed) {
          item.failed = true;
        }
        importResults.set(importType, result);
      }
    }
  }

  function updateItem(importType: ImportType, id: string, deletedId: string) {
    const result = importResults.get(importType);
    if (result) {
      const item = result.items.get(id);
      if (item) {
        item.deletedId = deletedId;
        importResults.set(importType, result);
      }
    }
  }

  function markItemComplete(importType: ImportType, id: string) {
    const result = importResults.get(importType);
    if (result) {
      const item = result.items.get(id);
      if (item && !item.failed) {
        item.success = true;
        importResults.set(importType, result);
      }
    }
  }
}

interface ImportResult {
  items: Map<string, ImportItem>;
  total: number;
  done: boolean;
}

interface ImportItem {
  id: string;
  name: string;
  errors: string[];
  deletedId?: string;
  skipReason?: ImportItemSkipReason;
  failed: boolean;
  success: boolean;
}

function getCheckboxLabel(importType: ImportType): string {
  switch (importType) {
    case "Snippet": return "Drop-Ins";
    case "TextExpansion": return "Expansions";
    case "NoteRule": return "Clinical Validations";
    case "NoteSectionForm": return "Custom Note Sections";
    case "NoteDefinition": return "Note Types";
    case "NoteTemplate": return "Note Templates";
    case "GroupNoteDefinition": return "Group Note Types";
    case "Group": return "Update Groups (Note Types)";
    case "Itinerary": return "Topics";
    case "SpeechRule": return "Clinical Recommendations";
    case "PracticePreference": return "Organization Preferences";
  }
}

function getInitialFormValues(): DataImportFormValues {
  const entries = importTypes.map(key => [key, true]);
  const requestedTypes = Object.fromEntries(entries);
  return { requestedTypes };
}

function getGeneralMessage(item: ImportItem): [string, Intent] {
  if (item.skipReason) {
    return [getSkipMessage(item.skipReason), Intent.NONE];
  }

  if (item.errors.length) {
    const intent = item.failed ? Intent.DANGER : Intent.WARNING;
    return item.errors.length === 1
      ? [item.errors[0], intent]
      : item.success ? ["Success with warnings", intent]
        : item.failed ? ["Failed with errors", intent]
          : ["", Intent.NONE];
  }

  if (item.success) {
    return ["Success!", Intent.SUCCESS];
  }

  return ["", Intent.NONE];
}

function getSkipMessage(skipReason: ImportItemSkipReason): string {
  switch (skipReason) {
    case "IdExisted": return "Skipped, previously copied";
    case "SourceNotFound": return "Skipped, source not found";
    case "TargetNotFound": return "Skipped, target not found";
    case "NoUpdate": return "Skipped, no update needed";
  }
}
